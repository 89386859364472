<template>
  <div></div>
</template>
<script>
import { URL } from "@/api/activeEmail";
export default {
  data() {
    return {
      info: {
        uid: "",
        token: "",
      },
    };
  },
  created() {
    this.info.uid = this.$route.params.uid;
    this.info.token = this.$route.params.token;
    this.activeEmail();
  },

  methods: {
    async activeEmail() {
      const data = this.info;
      var status = "success";
      const result = await this.$request({
        url: URL.ACTIVE_EMAIL,
        method: "POST",
        data,
      });
      if (result.data.code != 200) {
        status = "error";
      }
      this.$swal({
        text: result.data.messages,
        icon: status,
        timer: "3000",
        width: "450",
        padding: "10px",
      })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
